import React, { Component } from "react";
import { Link } from "react-router-dom";

class NavbarV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "logo";
    let anchor = "#";
    return (
      <header className="site-header-two site-header-two__ go-top  go-top">
        <nav className="main-nav__two stricky">
          <div className="container">
            <div className="main-nav__logo-box">
              <Link to="/">
                <img
                  src={publicUrl + "assets/images/Logo Web.png"}
                  alt={imgattr}
                />
              </Link>
            </div>
            {/* /.main-nav__logo-box */}
            <div className="main-nav__main-navigation">
              <ul className=" main-nav__navigation-box">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/service-v2">Services</Link>
                </li>
                <li>
                  <Link to="/portfolio-standard">Portfolio</Link>
                </li>
                {/* <li>
                      <Link to="/portfolio-details">Portfolio Details</Link>
                    </li> */}

                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            {/* /.main-nav__main-navigation */}
            <div className="main-nav__right">
              <a href="#" className="side-menu__toggler">
                <span />
              </a>
            </div>
            {/* /.main-nav__right */}
          </div>
          {/* /.container */}
        </nav>
        {/* /.main-nav__one */}
      </header>
    );
  }
}

export default NavbarV2;
