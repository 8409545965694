import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Contact extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div>
        <section className="contact-two">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="contact-two__content">
                  <div className="contact-two__block">
                    <h3>Don't Hesitate To Contact Us</h3>
                    <p>
                      Interested in finding out how we can help your business
                      thrive in the digital age? Contact us to discuss your
                      goals, vision, and project details.{" "}
                    </p>
                  </div>
                  {/* /.contact-two__block */}
                  <ul className="list-unstyled contact-two__info-list">
                    <li>
                      <div className="contact-two__info-list-title">
                        <i className="far fa-map-marked" />
                        Address :
                      </div>
                      {/* /.contact-two__info-list-title */}
                      <p>
                        JL Kinatagama Blok Kauman,<br></br> RT.013/RW.003,
                        Kaliwulu, Kec. Plered,<br></br> Kabupaten Cirebon, Jawa
                        Barat 45158. Indonesia
                      </p>
                    </li>
                    <li>
                      <div className="contact-two__info-list-title">
                        <i className="far fa-phone" />
                        Call Us :
                      </div>
                      {/* /.contact-two__info-list-title */}
                      <p>
                        <a href="tel:082219244660">0822-19244660</a>
                      </p>
                    </li>
                    <li>
                      <div className="contact-two__info-list-title">
                        <i className="far fa-envelope" />
                        Email Us :
                      </div>
                      {/* /.contact-two__info-list-title */}
                      <p>
                        <a href="mailto:support@rein-techno.my.id">
                          support@rein-techno.my.id
                        </a>
                      </p>
                    </li>
                  </ul>
                  {/* /.contact-two__info-list */}
                </div>
                {/* /.contact-two__content */}
              </div>
              {/* /.col-lg-5 */}
              <div className="col-lg-7">
                <form action="#" className="contact-two__form">
                  <div className="contact-two__block">
                    <h3>Get In Touch</h3>
                    <br></br>
                    <br></br>
                    <p>
                      Contact Software Development Team, Start Software Project,
                      Rein Technology Office, Software Development Consultation
                    </p>
                  </div>
                  {/* /.contact-two__block */}
                  <div className="row">
                    <div className="col-md-6">
                      <input type="text" placeholder="Full Name" />
                    </div>
                    {/* /.col-md-6 */}
                    <div className="col-md-6">
                      <input type="text" placeholder="Email Address" />
                    </div>
                    {/* /.col-md-6 */}
                    <div className="col-md-12">
                      <input type="text" placeholder="Subject" />
                    </div>
                    {/* /.col-md-12 */}
                    <div className="col-md-12">
                      <textarea
                        name="message"
                        placeholder="Message"
                        defaultValue={""}
                      />
                      <button
                        type="submit"
                        className="thm-btn contact-two__btn"
                      >
                        Send Message <i className="fa fa-angle-double-right" />
                      </button>
                      {/* /.thm-btn contact-two__btn */}
                    </div>
                    {/* /.col-md-12 */}
                  </div>
                  {/* /.row */}
                </form>
                {/* /.contact-two__form */}
              </div>
              {/* /.col-lg-7 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /.contact-two */}
        <iframe
          src="https://maps.google.com/maps?q=rein+technology+kaliwulu&t=&z=13&ie=UTF8&iwloc=&output=embed"
          className="google-map__contact"
          allowFullScreen
        />
      </div>
    );
  }
}

export default Contact;
