import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class TeamV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="team-one team-one__about-page">
        <div className="container">
          <div className="block-title-two text-center">
            <p>
              <span>Our Team</span>
            </p>
            <h3>
              Meet Our Exclusive <br /> Team Member
            </h3>
          </div>
          {/* /.block-title */}
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="team-one__single">
                <div className="team-one__image">
                  <img
                    src={publicUrl + "assets/images/team/bahrain.jpeg"}
                    alt={imagealt}
                  />
                </div>
                {/* /.team-one__image */}
                <div className="team-one__content">
                  <div className="team-one__social">
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fab fa-google-plus-g" />
                    </a>
                    <a href="#">
                      <i className="fab fa-linkedin" />
                    </a>
                  </div>
                  {/* /.team-one__social */}
                  <h3>Bahrainsyah Oksareinaldi</h3>
                  <p>CTO & Fullstack Developer</p>
                </div>
                {/* /.team-one__content */}
              </div>
              {/* /.team-one__single */}
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default TeamV2;
