import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class BrandTwo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="brand-one brand-one__home-two">
        <div className="container">
          <div className="block-title-two text-center">
            <p>our clients</p>
            <h3>
              We Have Many Others Partners <br /> Our Global Clients Here
            </h3>
          </div>
          {/* /.block-title-two */}
          <div
            className="brand-one__carousel owl-carousel owl-theme thm__owl-carousel"
            data-options='{"loop": true, "autoplay": true, "autoplayTimeout": 5000, "items": 6, "margin": 150, "smartSpeed": 700, "nav": false, "dots": false, "responsive": { "0": { "items": 2, "margin": 15 },"575": { "items": 3, "margin": 30 },"767": { "items": 3, "margin": 30 }, "991": { "items": 4, "margin": 30 }, "1199": { "items": 4, "margin": 75 }, "1200": { "items": 6 } }}'
          >
            <div className="item">
              <img
                src={publicUrl + "assets/images/resources/client-1.png"}
                alt=""
              />
            </div>
            <div className="item">
              <img
                src={publicUrl + "assets/images/resources/client-2.png"}
                alt=""
              />
            </div>
            <div className="item">
              <img src={publicUrl + "assets/images/resources/vas.png"} alt="" />
            </div>
            <div className="item">
              <img src={publicUrl + "assets/images/resources/bjt.png"} alt="" />
            </div>
            <div className="item">
              <img src={publicUrl + "assets/images/resources/pci.jpg"} alt="" />
            </div>
            <div className="item">
              <img
                src={publicUrl + "assets/images/resources/client-3.png"}
                alt=""
              />
            </div>
            <div className="item">
              <img
                src={publicUrl + "assets/images/resources/client-4.png"}
                alt=""
              />
            </div>
            <div className="item">
              <img
                src={publicUrl + "assets/images/resources/client-5.png"}
                alt=""
              />
            </div>
            <div className="item">
              <img
                src={publicUrl + "assets/images/resources/client-6.png"}
                alt=""
              />
            </div>
            <div className="item">
              <img
                src={publicUrl + "assets/images/resources/client-7.png"}
                alt=""
              />
            </div>
            <div className="item">
              <img
                src={publicUrl + "assets/images/resources/client-8.png"}
                alt=""
              />
            </div>
            <div className="item">
              <img
                src={publicUrl + "assets/images/resources/client-9.png"}
                alt=""
              />
            </div>
          </div>
          {/* /.brand-one__carousel owl-carousel owl-theme thm__owl-carousel */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default BrandTwo;
