import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class CtaV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <section
        className="cta-two  go-top"
        style={{
          backgroundImage:
            "url(" + publicUrl + "assets/images/backgrounds/bg-ss.png)",
        }}
      >
        <div className="container text-center">
          <h3>
            We deliver consistent quality,<br></br> from design to deployment
            and beyond.
          </h3>
          <Link to="/contact" className="thm-btn">
            Read More <i className="fa fa-angle-double-right" />
          </Link>
          {/* /.thm-btn */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default CtaV2;
