import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceV6 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="service-two service-two__service-page  go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="service-two__block">
                <div className="block-title-two text-left">
                  <p>Our Services</p>
                  <h3>Custom Software Development Services</h3>
                </div>
                {/* /.block-title-two */}
                <p>
                  Rein Technology offers end-to-end software solutions that are
                  tailored to drive growth and efficiency.{" "}
                </p>
                <ul className="list-unstyled video-one__list">
                  <li>
                    <i className="far fa-check" />
                    <b>Custom Software Development : </b>We create scalable
                    software tailored to your specific business processes,
                    ensuring seamless integration and flexibility.
                  </li>
                  <li>
                    <i className="far fa-check" />
                    <b>Mobile App Development : </b>From initial design to final
                    deployment, we develop intuitive, user-focused mobile
                    applications for iOS and Android that engage and retain
                    users.
                  </li>
                  <li>
                    <i className="far fa-check" />
                    <b>Cloud Solutions & Migration : </b>With secure, scalable
                    cloud integration, we streamline your operations and enable
                    seamless access and scalability for future growth.
                  </li>
                  <li>
                    <i className="far fa-check" />
                    <b>UX/UI Design Services : </b>Our design team creates
                    interfaces that prioritize user experience, aligning
                    functionality with visually engaging design for an optimal
                    user journey.
                  </li>
                </ul>
                {/* /.list-unstyled video-one__list */}
                <Link to="/service" className="thm-btn">
                  View More <i className="fa fa-angle-double-right" />
                </Link>
                {/* /.thm-btn */}
              </div>
              {/* /.service-two__block */}
            </div>
            {/* /.col-lg-5 */}
            <div className="col-lg-7 d-flex justify-content-center">
              <div className="faq-two__image">
                <img
                  src={
                    publicUrl +
                    "assets/images/resources/caf9e8dbf62fb852eaf38cd1d9a3958bcca2bb1794f5417498cda4bd4fc7c2c4.png"
                  }
                  alt="imagealt"
                />
              </div>
              {/* /.faq-two__image */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default ServiceV6;
