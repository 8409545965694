import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutPage extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="service-two service-two__service-page service-two__about-page  go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="service-two__image">
                <img
                  src={
                    publicUrl +
                    "assets/images/resources/b2ca3367efa09c23b5c7015cc2a02f8d41d54e69460265dbeba22fbf4b0b264f.png"
                  }
                  alt={imagealt}
                />
              </div>
              {/* /.service-two__image */}
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6">
              <div className="service-two__block">
                <div className="block-title-two text-left">
                  <p>about us</p>
                  <h3>About Rein Technology</h3>
                </div>
                {/* /.block-title-two */}
                <p>
                  Rein Technology is a trusted software development company
                  committed to creating personalized, future-ready digital
                  solutions. By blending advanced technology with strategic
                  insights, we help clients stand out in competitive markets. We
                  don’t just build software; we build experiences that support
                  your vision for growth.{" "}
                </p>
                <ul className="list-unstyled video-one__list">
                  <li>
                    <i className="far fa-check" />
                    Innovation & Agility : Staying at the forefront of
                    technology to deliver adaptable solutions.
                  </li>
                  <li>
                    <i className="far fa-check" />
                    Client-Centric Focus : Every project is crafted with our
                    clients’ success as the ultimate goal.
                  </li>
                  <li>
                    <i className="far fa-check" />
                    Commitment to Excellence : We deliver consistent quality,
                    from design to deployment and beyond.
                  </li>
                  <li>
                    <i className="far fa-check" />
                    Reliability You Can Trust : We take pride in our
                    accountability, always delivering on time and providing
                    dependable support.
                  </li>
                </ul>
                {/* /.list-unstyled video-one__list */}
                <Link to="/service" className="thm-btn">
                  Read More <i className="fa fa-angle-double-right" />
                </Link>
                {/* /.thm-btn */}
              </div>
              {/* /.service-two__block */}
            </div>
            {/* /.col-lg-5 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default AboutPage;
