import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV6 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="about-three about-three__about-page  go-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="about-three__content">
                <div className="block-title-two text-left">
                  <p>What we do</p>
                </div>
                {/* /.block-title-two */}
                <p>
                  Transforming ideas into powerful digital experiences—Rein
                  Technology is your partner in scalable, high-quality software
                  development.
                </p>
                <h4>Started With Us</h4>
                <p>
                  Our portfolio showcases a diverse range of projects that
                  highlight our expertise across industries like retail,
                  healthcare, finance, and more. Discover how we’ve helped
                  businesses elevate their digital presence with strategic,
                  high-impact solutions.
                </p>
                <Link
                  to="/service-details"
                  className="thm-btn about-three__btn"
                >
                  Read More <i className="fa fa-angle-double-right" />
                </Link>
                {/* /.thm-btn about-three__btn */}
              </div>
              {/* /.about-three__content */}
            </div>
            {/* /.col-lg-6 */}
            <div className="col-xl-6 d-flex justify-content-center">
              <div className="video-one__box">
                <img
                  src={
                    publicUrl +
                    "assets/images/resources/4087a5fb39ee8fa8d583ff8e34a243714b7c066d0552fd4964c7e6f35c24912d.png"
                  }
                  alt={imagealt}
                />

                {/* /.video-popup video-one__btn */}
              </div>
              {/* /.video-one__box */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default AboutV6;
